var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row align-items-center justify-content-center"},[_c('div',{staticClass:"col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5"},[_c('h1',{staticClass:"display-4 text-center mb-3"},[_vm._v(" Login ")]),_c('p',{staticClass:"text-muted text-center mb-5"},[_vm._v(" "+_vm._s(_vm.subtitleText)+" ")]),(_vm.loginError)?_c('p',{class:("alert " + (_vm.login.status === 'success' && _vm.verifySMS.status === 'success' ? 'alert-success' : 'alert-danger') + " mt-3 mb-4"),attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.loginError)+" ")]):_vm._e(),_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var changed = ref.changed;
return [_c('form',{class:{ 'is-loading': (_vm.loading || _vm.login.error && _vm.login.error.code === 'auth/multi-factor-auth-required') },on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit($event)}}},[(_vm.loginFormType === 'normal')?_c('div',[_c('FormInput',{attrs:{"autocomplete":"email","autofocus":"","label":"E-Mail","name":"email","placeholder":"name@address.com","rules":"required","type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"password","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")])]),_c('div',{staticClass:"col-auto"},[_c('router-link',{staticClass:"form-text small text-muted",attrs:{"tabindex":"-1","to":"/reset"}},[_vm._v(" Forgot password? ")])],1)]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:{'form-control' : true, 'is-invalid': errors[0]},attrs:{"autocomplete":"current-password","id":"password","name":"password","placeholder":"Enter your password","type":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('FormInvalidMessage',{attrs:{"errors":errors}})]}}],null,true)})],1):_vm._e(),(_vm.loginFormType === 'phoneNumber')?_c('ValidationProvider',{ref:"phoneNumberValidation",staticClass:"input-group",attrs:{"name":"phoneNumber","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"number__example text-muted"},[_vm._v(" Number format: +31612345678 ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumber),expression:"phoneNumber"}],ref:"phoneNumber",class:{'form-control input__sms__code' : true, 'is-invalid': errors[0]},attrs:{"id":"phoneNumber","name":"phoneNumber","placeholder":"Enter your phone number","required":"","type":"tel"},domProps:{"value":(_vm.phoneNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phoneNumber=$event.target.value}}}),_c('FormInvalidMessage',{attrs:{"errors":errors}})]}}],null,true)}):_vm._e(),(_vm.loginFormType === 'smsCode')?_c('ValidationProvider',{ref:"smsCodeValidation",staticClass:"input-group verification",attrs:{"name":"smsCode","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"verification__text"},[_vm._v(" "+_vm._s(("An sms has been sent to your phone: " + ((_vm.login.payload && _vm.login.payload.hint && _vm.login.payload.hint.phoneNumber) || _vm.hiddenPhoneNumber)))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.smsCode),expression:"smsCode"}],ref:"smsCode",class:{'form-control input__sms__code' : true, 'is-invalid': errors[0]},attrs:{"id":"smsCode","name":"smsCode","placeholder":"Enter the sms code","type":"text"},domProps:{"value":(_vm.smsCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.smsCode=$event.target.value}}}),_c('FormInvalidMessage',{attrs:{"errors":errors}})]}}],null,true)}):_vm._e(),_c('button',{staticClass:"btn btn-lg btn-block btn-primary mt-4 btn-cursor",attrs:{"disabled":!_vm.sendButtonEnabled || invalid || !(changed || !!_vm.loginError)}},[_vm._v(" "+_vm._s(_vm.loginButtonText)+" ")])],1),_c('div',{attrs:{"id":"recaptcha-container"}})]}}])})],1),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block",attrs:{"aria-hidden":"true"}},[_c('div',{staticClass:"bg-cover vh-100 mt--1 mr--3"})])}]

export { render, staticRenderFns }